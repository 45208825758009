'use client'

import { type ModalAPI } from 'fukku/Modal'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { StoreLocatorModal } from 'maps/components/StoreLocatorModal/StoreLocatorModal'
import { useRef } from 'react'

import styles from './StoreLocatorButton.module.scss'
import texts from 'fukku/styles/texts.module.scss'

export const StoreLocatorButton = () => {
	const { t } = useLabels()

	const storeLocatorModalRef = useRef<ModalAPI>(null)

	const onClick = () => {
		storeLocatorModalRef.current?.show()
	}

	return (
		<>
			<button
				type='button'
				aria-label={t('storeFinder.search.stores.label')}
				className={`${texts.bodyMRegular} ${styles.storeLocatorButton}`}
				onClick={onClick}
			>
				{t('storeFinder.search.stores.label')}
			</button>
			<StoreLocatorModal storeLocatorModalRef={storeLocatorModalRef} />
		</>
	)
}
