import { MODAL_TYPE, Modal, type ModalAPI } from 'fukku/Modal'
import dynamic from 'next/dynamic'

interface StoreLocatorModalProps {
	storeLocatorModalRef: React.RefObject<ModalAPI>
}

const StoreLocator = dynamic(() =>
	import('./StoreLocator/StoreLocator').then((mod) => mod.StoreLocator)
)

export const StoreLocatorModal = ({
	storeLocatorModalRef,
}: StoreLocatorModalProps) => {
	return (
		<Modal
			data-testid='footer.storeLocatorModal'
			name='store-locator-modal'
			title='back-in-stock-modal'
			type={MODAL_TYPE.XL}
			ref={storeLocatorModalRef}
		>
			<StoreLocator />
		</Modal>
	)
}
