import type { Brand, BrandId } from 'types/brands'

import { getBrandTranslationKeys } from './getBrandTranslationKeys'

const translateBrandPath = (
	brand: Brand,
	translate: (key: string) => string
): string => {
	return translate(getBrandTranslationKeys(brand.id).urlKey)
		.toLowerCase()
		.replace(' ', '-')
}

export const brandPath = ({
	brandId,
	brands,
	translate,
	initialPath = '',
}: {
	brandId: BrandId
	brands: Brand[]
	translate: (key: string) => string
	initialPath?: string
}): string =>
	brands.reduce((path, brand) => {
		if (brand.id === brandId) {
			const pathBrand = translateBrandPath(brand, translate)

			return `${path}/${pathBrand}`
		}

		const subBrand = brand.subBrands?.find((b) => b.id === brandId)

		if (subBrand && brand.subBrands) {
			const pathBrand = translateBrandPath(brand, translate)

			return brandPath({
				brandId,
				translate,
				brands: brand.subBrands,
				initialPath: `${path}/${pathBrand}`,
			})
		}

		return path
	}, initialPath)
